<template>
  <div>
    <LoadingSpinner :isLoading="isLoading" />
    <ValidationObserver ref="formUpdateFloorPlan">
      <div class="flex flex-col">
        <div class="w-full bg-action-bar px-2 py-2 clearfix">
          <div class="float-left flex flex-wrap">
            <div class="mx-2 mt-2 lg:mt-0 flex">
              <div class="">
                <input type="file" accept="image/*" ref="floorPlanImg" @change="fileChangeUpdate()" class="rounded w-full text-sm text-gray-900 focus:outline-none transition duration-500 py-1" />
              </div>
              <div class="flex flex-wrap px-4">
                <input class="w-10/12" type="range" :min="minCircleRadius" :max="maxCircleRadius" step="1" v-model.number="circleRadius" @input="changeRadiusAllCircle()" />
                <div class="w-2/12 text-lg text-secondary-700 mt-1 pl-2">{{ circleRadius }}</div>
              </div>
            </div>
          </div>
          <div class="float-right flex flex-wrap">
            <div class="mx-2 mt-2 lg:mt-0 flex">
              <button class="btn-red-outline mx-2 mt-3 py-2 sm:mt-0" @click.prevent="resetFloorPlan()">
                Reset
                <BaseIcon icon="undo" class="ml-1" />
              </button>
              <button class="btn-green mx-2 mt-3 py-2 sm:mt-0" @click.prevent="updateFloorPlan()">
                Spara
                <BaseIcon icon="save" class="ml-1" />
              </button>
            </div>
          </div>
        </div>

        <div class="w-full mt-6">
          <div class="flex flex-wrap">
            <div ref="canvasContainer" class="w-full md:w-10/12">
              <div v-if="unit && configImg.image" class="relative z-10">
                <div class="absolute right-0 flex flex-col">
                  <button class="bg-secondary-300 text-secondary-700 px-2 py-1 border-b border-secondary-400 mt-1 mr-2" @click.prevent="zoomButton(1)"><BaseIcon icon="search-plus" /></button>
                  <button class="bg-secondary-300 text-secondary-700 px-2 py-1 border-b border-secondary-400 mr-2" @click.prevent="zoomButton(-1)"><BaseIcon icon="search-minus" /></button>
                  <button class="bg-secondary-300 text-secondary-700 px-2 py-1 mr-2" @click.prevent="zoomReset()"><BaseIcon icon="undo" /></button>
                </div>
              </div>
              <v-stage v-if="unit && configImg.image" :config="configStage" ref="stage" class="border border-secondary-300" @wheel="zoomWheel">
                <v-layer>
                  <v-image :config="configImg" @click="addCircle" @touchend="addCircle" />
                  <v-circle ref="circle" v-for="(z, i) in unit.floorPlanZones" :key="i" :config="{ ...z.circle, draggable: true }" @dragstart="circleDragStart" @dragend="circleDragEnd($event, i)" @mousemove="showLabel($event, z, true)" @mouseout="showLabel($event, z, false)" @touchend="showLabel($event, z, true)" />
                </v-layer>
                <v-layer>
                  <v-label :config="configLabelGroup">
                    <v-tag :config="configTag" />
                    <v-text :config="configText" />
                  </v-label>
                </v-layer>
              </v-stage>
            </div>
            <div class="w-full md:w-2/12 flex flex-col px-3 bg-secondary-200 border border-secondary-300 overflow-y-scroll" v-if="unit" :style="{ height: configStage.height + 2 + 'px' }">
              <div v-for="(z, i) in unit.floorPlanZones" :key="i" class="flex flex-col border-b border-secondary-300 pb-3 pt-3">
                <ValidationProvider :name="'Tag ' + (i + 1)" rules="required" v-slot="{ classes, errors }">
                  <div class="input-validate" :class="classes">
                    <select v-model="z.tag_id" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b border-gray-300 hover:border-gray-400 transition duration-500 px-3 pt-2 pb-2">
                      <option value="">Välj Tag</option>
                      <option :value="o.modelnumber" v-for="(o, j) in tags" :key="j">{{ o.unit_name }}</option>
                    </select>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div class="mb-2">
                  <input type="text" v-model="z.zone_name" placeholder="Namn" class="bg-gray-100 rounded w-full text-xs text-gray-600 focus:outline-none border-b border-gray-300 px-3 pt-1 pb-1" />
                </div>
                <div>
                  <div class="w-full flex flex-wrap mt-1 mb-3">
                    <input class="w-10/12" type="range" :min="minCircleRadius" :max="maxCircleRadius" step="1" v-model.number="z.circle.radius" @input="changeRadiusCircle(i)" />
                    <div class="w-2/12 text-sm text-secondary-700 pl-2">{{ z.circle.radius }}</div>
                  </div>
                </div>
                <div class="clear-both">
                  <div class="float-left">
                    <button class="btn-blue-outline text-xxs" @click.prevent="focusZone(z, i)">
                      <BaseIcon icon="bullseye" />
                    </button>
                  </div>
                  <div class="float-right">
                    <button class="btn-red-outline text-xxs" @click.prevent="removeZone(i)">
                      <BaseIcon icon="trash" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Konva from "konva";

export default {
  title() {
    return `Floor Plan | SecurCloud`;
  },
  data() {
    return {
      unit_id: this.$route.params.unit_id,
      customer_id: this.$route.params.customer_id,
      isLoading: false,
      unit: null,
      tags: [],
      minCircleRadius: 5,
      maxCircleRadius: 72,
      circleRadius: 10,
      configStage: {
        width: window.innerWidth,
        height: window.innerHeight,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      },
      selectedImg: null,
      imageSize: { width: 1, height: 1 },
      configImg: {
        x: 0,
        y: 0,
        image: null,
        width: 1,
        height: 1,
      },
      configLabelGroup: {
        x: 0,
        y: 0,
        opacity: 1,
        visible: false,
        scaleX: 1,
        scaleY: 1,
      },
      configTag: {
        fill: "black",
        pointerDirection: "none",
        lineJoin: "round",
        shadowColor: "black",
        shadowBlur: 10,
        shadowOffsetX: 10,
        shadowOffsetY: 10,
        shadowOpacity: 0.5,
      },
      configText: {
        text: "",
        fontFamily: "sans-serif",
        fontSize: 18,
        padding: 10,
        fill: "white",
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    getUnitAlarms() {
      this.isLoading = true;

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/unit/alarms/${this.unit_id}`)
        .then((response) => {
          this.unit = response.data.unit;

          if (this.unit.floorPlanImg) this.drawImageOnCanvas(this.unit.floorPlanImg);

          let tags = _.filter(response.data.unit_alarms, function(o) {
            return /(SRT334)|(TWIG Beacon)/.test(o.type);
          });
          this.tags = tags;

          this.setPageTitle(`${this.unit.name} - Floor Plan`, "unit_floor_plan");
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleError(error);
          this.isLoading = false;
        });
    },

    updateFloorPlan() {
      this.$refs.formUpdateFloorPlan.validate().then((success) => {
        if (!success) {
          return;
        }

        let headers = {
          "Content-Type": "multipart/form-data",
          "X-Access-Token": this.$store.state.token,
        };

        let formData = new FormData();

        formData.append("floorPlanZones", JSON.stringify(this.unit.floorPlanZones));
        if (this.selectedImg) formData.append("image", this.selectedImg);
        if (this.unit.floorPlanImg == null) formData.append("remove_img", this.unit.floorPlanImg);

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/unit/floor-plan/${this.unit._id}`, formData, headers)
          .then(() => {
            //eslint-disable-line
            this.$router.push({ name: "alarms", params: { type: "customer", customer_id: this.customer_id, unit_id: this.unit_id, id: "-" } });
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    fileChangeUpdate() {
      var vm = this;

      if (this.$refs.floorPlanImg.files.length > 0) {
        this.unit.floorPlanZones = [];
        this.configLabelGroup.visible = false;
        this.selectedImg = this.$refs.floorPlanImg.files[0];

        let fileReader = new FileReader();
        fileReader.onload = function(e) {
          vm.drawImageOnCanvas(e.target.result);
        };
        fileReader.readAsDataURL(this.selectedImg);
      } else this.resetFloorPlan();
    },

    drawImageOnCanvas(image) {
      var vm = this;

      var img = new Image();
      img.src = image;

      img.onload = function() {
        vm.imageSize.width = this.width;
        vm.imageSize.height = this.height;
        vm.checkScreenSize();

        vm.configImg.image = img;
      };
    },

    addCircle(event) {
      let point = this.getRelativePointerPosition(event.target.getStage());

      this.unit.floorPlanZones.push({
        circle: {
          x: point.x,
          y: point.y,
          radius: this.circleRadius,
          fill: "#0066cc",
          strokeWidth: 2 * this.circleRadius,
          stroke: "rgb(0 102 204 / 35%)",
          shadowColor: "black",
          shadowBlur: 10,
          shadowOffset: { x: 10, y: 10 },
          shadowOpacity: 0.5,
          fillAfterStrokeEnabled: true,
          perfectDrawEnabled: false,
        },
        tag_id: "",
        zone_name: "",
      });
    },

    removeZone(i) {
      this.unit.floorPlanZones.splice(i, 1);
      this.configLabelGroup.visible = false;
      this.configText.text = "";
    },

    circleDragStart() {
      this.configLabelGroup.visible = false;
    },

    circleDragEnd(event, i) {
      this.unit.floorPlanZones[i].circle.x = event.target.x();
      this.unit.floorPlanZones[i].circle.y = event.target.y();
    },

    changeRadiusAllCircle() {
      this.unit.floorPlanZones.forEach((z) => {
        z.circle.radius = this.circleRadius;
        z.circle.strokeWidth = 2 * this.circleRadius;
      });
    },

    changeRadiusCircle(i) {
      this.unit.floorPlanZones[i].circle.strokeWidth = 2 * this.unit.floorPlanZones[i].circle.radius;
    },

    showLabel(event, zone, show) {
      let stage = this.$refs.stage.getNode();

      if (show) {
        let tag_name = "_______";
        let tag = _.find(this.tags, { modelnumber: zone.tag_id });
        if (tag) {
          tag_name = tag.unit_name;
          if (zone.zone_name) tag_name += `\r\n${zone.zone_name}`;
        }

        let point = { x: zone.circle.x, y: zone.circle.y };
        if (event) {
          point = this.getRelativePointerPosition(event.target.getStage());
          stage.container().style.cursor = "move";
        }

        this.configLabelGroup.x = point.x;
        this.configLabelGroup.y = event ? point.y + 45 : point.y;
        this.configLabelGroup.visible = true;
        this.configText.text = `${tag_name}`;
      } else {
        this.configLabelGroup.visible = false;
        this.configText.text = "";
        stage.container().style.cursor = "auto";
      }
    },

    getRelativePointerPosition(node) {
      const transform = node.getAbsoluteTransform().copy();
      transform.invert();
      const pos = node.getStage().getPointerPosition();
      return transform.point(pos);
    },

    focusZone(z, i) {
      this.zoomReset();
      this.showLabel(null, z, true);
      this.pulseCircle(i);
    },

    pulseCircle(i) {
      let circle = this.$refs.circle[i].getNode();
      let strokeWidth = this.unit.floorPlanZones[i].circle.strokeWidth;

      let animation = new Konva.Animation(function(frame) {
        let scale = Math.sin((frame.time * 2 * Math.PI) / 2000);
        circle.strokeWidth(strokeWidth * scale);
      }, circle.getLayer());

      animation.start();

      setTimeout(() => {
        animation.stop();
        circle.strokeWidth(strokeWidth);
      }, 2500);
    },

    checkScreenSize() {
      let canvasContainer = this.$refs.canvasContainer;

      if (canvasContainer) {
        let containerWidth = canvasContainer.offsetWidth;

        if (this.imageSize.width >= 1900) {
          let ratio = this.imageSize.height / this.imageSize.width;
          this.imageSize.width = 1900;
          this.imageSize.height = this.imageSize.width * ratio;
        }

        let scale = containerWidth / this.imageSize.width;

        this.configImg.width = this.imageSize.width;
        this.configImg.height = this.imageSize.height;

        this.configStage.width = this.imageSize.width * scale;
        this.configStage.height = this.imageSize.height * scale;

        this.configStage.scaleX = scale;
        this.configStage.scaleY = scale;

        if (containerWidth < 500) {
          this.configLabelGroup.scaleX = 0.4 / scale;
          this.configLabelGroup.scaleY = 0.4 / scale;
        } else {
          this.configLabelGroup.scaleX = 1 / scale;
          this.configLabelGroup.scaleY = 1 / scale;
        }

        if (this.$refs.stage) {
          var stage = this.$refs.stage.getStage();
          stage.position({ x: 0, y: 0 });
        }
      }
    },

    resetFloorPlan() {
      this.unit.floorPlanImg = null;
      this.unit.floorPlanZones = [];
      this.circleRadius = 20;
      this.configLabelGroup.visible = false;
      this.imageSize = { width: 1, height: 1 };
      this.configStage = {
        width: window.innerWidth,
        height: window.innerHeight,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      };
      this.selectedImg = null;
      this.configImg.image = null;
      this.configImg.width = 1;
      this.configImg.height = 1;
      this.configLabelGroup.scaleX = 1;
      this.configLabelGroup.scaleY = 1;
      this.$refs.floorPlanImg.value = null;
    },
  },

  created() {
    this.getUnitAlarms();
    window.addEventListener("resize", this.checkScreenSize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>
